import React from 'react';
import './App.css';
import LandingPage from './Pages/LandingPage';
// import { BrowserRouter,Routes,Route } from 'react-router-dom';
import About from './component/About';
import ContactUs from './component/ContactUs';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Header from './component/Header';
import Footer from './component/Footer';
import OurServices from './component/OurServices';
import ScrollToTop from "./component/ScrollToTop"
import Properties from './component/Properties';
import Career from './component/Career';
import Blogs from './component/Blogs';
import { Context } from './component/Context';
import AboutUsDropDownMenu from './component/AboutUsDropDownMenu';
import OurProductCollections from './component/OurProductCollections';
import ChristmasOverlay from './component/ChristmassWelcomeMessage';
import { useState,useEffect } from 'react';
import FooterBanner from './component/FooterBanner';
import ChristmassImg from './Images/ChristmassImg.png';



const App = () => {

  const [showOverlay, setShowOverlay] = useState(true);

  useEffect(() => {
    // Set a timer to hide the overlay after 2 seconds
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 4000);

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);





  return (
   <BrowserRouter>
   <ScrollToTop/>
   {showOverlay&&<ChristmasOverlay/>}
   <Header/>
      <Routes>
          <Route path="/" element={<LandingPage/>}/>
          {/* <Route path="/aboutus" element={<About/>}/> */}
          <Route path="/contactus" element={<ContactUs/>}/>
          <Route path="/ourservices" element={<OurServices/>}/>
          <Route path="/properties" element={<Properties/>}/>
          <Route path="/career" element={<Career/>}/>
          <Route path="/blogs" element={<Blogs/>}/>
          <Route path="/aboutusUI" element={<AboutUsDropDownMenu/>}/>
          <Route path="/ourproductcollections" element={<OurProductCollections/>}/>
          {/* <Route path="/context" element={<Context/>}/> */}
        </Routes>
        <img src={ChristmassImg} alt="Logo2" className='SideLogo2' />
        <FooterBanner/>
      <Footer/>
   </BrowserRouter>
    
  )
}

export default App
